import heroMan2Svg from "../img/hero-man-2.svg";
import ellipsePurpleSvg from "../img/ellipse-purple.svg";
import ellipseYellowSvg from "../img/ellipse-yellow.svg";
import forkifyJpg from "../img/forkify.jpg";
import toltechJpg from "../img/toltech.jpg";
import reactQuizJpg from "../img/reactquiz.jpg";
import natoursJpg from "../img/natours.jpg";
import fastReactPizzaJpg from "../img/fast-react-pizza.jpg";
import theWildOasisJpg from "../img/the-wild-oasis.jpg";
import ulcayahsapJpg from "../img/ulcayahsap.jpg";
import githubPng from "../img/github.png";
import supabaseSvg from "../img/supabase-logo-icon.svg";
import styledComponentsSvg from "../img/styled-components.svg";
import reactRouterSvg from "../img/react-router-icon.svg";
import reduxSvg from "../img/redux-icon.svg";
import sassSvg from "../img/sass-icon.svg";
import webpackSvg from "../img/webpack-icon.svg";
import netlifySvg from "../img/netlify-icon.svg";
import reactSvg from "../img/react-icon.svg";
import viteSvg from "../img/vite-icon.svg";
import vercelSvg from "../img/vercel-icon.svg";
import nodejsSvg from "../img/nodejs-icon.svg";

// Images
const fastReactPizza = document.getElementById("fast-react-pizza");
const theWildOasis = document.getElementById("the-wild-oasis");
const heroMan = document.getElementById("hero-man");
const ellipsePurple = document.getElementById("ellipse-purple");
const ellipseYellow = document.getElementById("ellipse-yellow");
const forkify = document.getElementById("forkify");
const toltech = document.getElementById("toltech");
const reactquiz = document.getElementById("reactquiz");
const natours = document.getElementById("natours");
const ulcayahsap = document.getElementById("ulcayahsap");

// Icons
const supabase = document.getElementsByClassName("supabase");
const styledComponents = document.getElementsByClassName("styled-components");
const reactRouter = document.getElementsByClassName("react-router");
const redux = document.getElementsByClassName("redux");
const sass = document.getElementsByClassName("sass");
const webpack = document.getElementsByClassName("webpack");
const netlify = document.getElementsByClassName("netlify");
const react = document.getElementsByClassName("react");
const vite = document.getElementsByClassName("vite");
const vercel = document.getElementsByClassName("vercel");
const nodejs = document.getElementsByClassName("nodejs");
const githubLogo = document.getElementsByClassName("github-logo");

fastReactPizza.src = fastReactPizzaJpg;
theWildOasis.src = theWildOasisJpg;
heroMan.src = heroMan2Svg;
ellipsePurple.src = ellipsePurpleSvg;
ellipseYellow.src = ellipseYellowSvg;
forkify.src = forkifyJpg;
toltech.src = toltechJpg;
reactquiz.src = reactQuizJpg;
natours.src = natoursJpg;
ulcayahsap.src = ulcayahsapJpg;

const setSrcForElements = (elements, src) => {
  Array.from(elements).forEach((element) => {
    element.src = src;
  });
};
setSrcForElements(supabase, supabaseSvg);
setSrcForElements(styledComponents, styledComponentsSvg);
setSrcForElements(reactRouter, reactRouterSvg);
setSrcForElements(redux, reduxSvg);
setSrcForElements(sass, sassSvg);
setSrcForElements(webpack, webpackSvg);
setSrcForElements(netlify, netlifySvg);
setSrcForElements(react, reactSvg);
setSrcForElements(vite, viteSvg);
setSrcForElements(vercel, vercelSvg);
setSrcForElements(nodejs, nodejsSvg);
setSrcForElements(githubLogo, githubPng);
